import React from "react";
import { Route, Redirect } from "react-router-dom";
import { APP_NAME_MINI } from "../Constants";
import useDocumentTitle from "../Hooks/useDocumentTitle";

const CustomRoute = ({ component: Component, children, title, ...rest }) => {
  useDocumentTitle(`${APP_NAME_MINI} | ${title}`);

  return  <Route
  {...rest}
  render={(props) => <Component {...props} children={children} />
     
  }
/>;
};

export default CustomRoute;
