import { Col, Form, Row, message } from 'antd'
import ReactQuill from 'react-quill';
import React, { useState } from 'react'
import { TextBox } from "../../../Components/common/Forms/TextBox"
import { SimpleHeading } from "../../../Components/common/Heading"
import { SimpleButton } from "../../../Components/common/Buttons"
import { useTranslate } from 'react-redux-multilingual'
import useUrduStyles from '../../../Hooks/useUrduStyles'
import {db} from "../../../Services/firebaseConfig"
import { collection, addDoc } from 'firebase/firestore';
import style from "./index.module.less"
import 'react-quill/dist/quill.snow.css'



const AddVideos = () => {

  const [buttonLoading, setButtonLoading] = useState(false)
  const t = useTranslate();
  const urduStyles = useUrduStyles();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log("on finish", values);
    setButtonLoading(true);
   
    try {

      const data = {
        ...values,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        isDeleted: false,
      };

      const collectionName ="videos"
      const collectionRef = collection(db, collectionName);
      const docRef = await addDoc(collectionRef, data);

      console.log(`Document written with ID: ${docRef.id}`);


    } catch (error) {
      console.log(error)
      message.error(error.message);
    } finally {
      setButtonLoading(false);
      form.resetFields()

    }

  }
  const onFinishFailed = (e) => {
    console.log("on Finish failed", e)
  }


  return (
    <div className={style.blogWrapper}>
      <Form
        scrollToFirstError={true}
        form={form}
        // initialValues={initialValues}
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={[16, 16]} >
          <Col lg={24} md={24} sm={24} xs={24}>
            <SimpleHeading
              heading={t('addVideo')}
              size='30'
            />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
          <SimpleHeading
              heading={t('addVideoTitle')}
              size='20'
            />
            <TextBox
              type='text'
              name='title'
              placeholder={t('addVideoTitle')}
              validationKey='required'
              css={`${style.blogName} ${urduStyles}`}
             
            />
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <SimpleHeading
              heading={t('addVideoUrl')}
              size='20'
            />
            <TextBox
              type='text'
              name='url'
              placeholder={t('addVideoUrl')}
              validationKey='required'
              css={`${style.blogName} ${urduStyles}`}
             
            />
            
          </Col>
          <Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <SimpleButton
                type='primary'
                size='small'
                shape='round'
                text={t('submit')}
                hType='submit'
                loading={buttonLoading}
                className={style.addButton}
              />
            </Col>
          </Col>
        </Row>


      </Form>
    </div>
  )
}

export default AddVideos