import React from "react";
import { Layout } from "antd";
import style from "./index.module.less";
import LayoutHeader from "./Header";
import LayoutFooter from "./Footer";
import AuthService from "../Utils/Auth.utils";
import { useEffect } from "react";
import MainApp from "../Containers/App/MainApp";
import UnAuthenticatedApp from "../Routes/UnauthenticatedApp";
import { useSelector } from "react-redux";
const { Header, Content, Footer } = Layout;

const LayoutWrapper = ({ children }) => {
  const isTokenExist = AuthService.isTokenExist();
  
  const RegisterRoutes = () => {
    
      console.log("token exist", isTokenExist);
      if (isTokenExist) {
        console.log("Authenticated Detected!");
        return <MainApp />;
      } else {
        console.log("Unauthenticated Detected!");
        return <UnAuthenticatedApp />;
      }
    }
  
  
  useEffect(() => {
    console.log("LayoutWrapper useEffect");
  RegisterRoutes();
  }, [isTokenExist]);



  return (
    <div>
      <Layout>
        {
          !isTokenExist && (
            <Header className={style.header}>
            <LayoutHeader />
          </Header>
          )

        }
        <Content className={style.contentContainer}>{RegisterRoutes()}</Content>
        {
          !isTokenExist && (
            <Footer className={style.footer}>
            <LayoutFooter />
          </Footer>
          )
        }
       
      </Layout>
    </div>
  );
};

export default LayoutWrapper;
