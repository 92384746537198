import React from 'react'
import style from './index.module.less';
import { useTranslate } from 'react-redux-multilingual';
import { Link } from "react-router-dom";
import { SimpleParagraph } from '../Components/common/Paragraph';
import { FacebookOutlined, InstagramOutlined, TwitterOutlined } from '@ant-design/icons';
import useUrduStyles from '../Hooks/useUrduStyles';


const Footer = () => {
  const t = useTranslate();
  const urduStyles = useUrduStyles();

  const items = [
    {
      label: (
        <Link to='/videos'>
          <SimpleParagraph
            paragraph={t('videos')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "videos",
    }, 
    {
      label: (
        <Link to='/articles'>
          <SimpleParagraph
            paragraph={t('articles')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "articles",
    },
    {
      label: (
        <Link to='/blogs'>
          <SimpleParagraph
            paragraph={t('blogs')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "blogs",
    },
    {
      label: (
        <Link to='/about'>
          <SimpleParagraph
            paragraph={t('about')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
            />
        </Link>
      ),
      key: "about",
      children: [{
        label: <Link to='/my-life-stories'>{t('myLifeStories')}</Link>,
        key: "myLifeStories",
      }]
    }, 
    {
      label: (
        <Link to='/'>
          <SimpleParagraph
            paragraph={t('home')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "home",
    },
  ];

  const socialIcons = [
    {
      platformIcon: <InstagramOutlined />,
      key: 'instagram',
      link: 'https://www.instagram.com/arifulhaqarifsahafi/',
    },
    {
      platformIcon: <FacebookOutlined />,
      key: 'facebook',
      link: 'https://www.facebook.com/ArifulHaque1940',
    },
    {
      platformIcon: <TwitterOutlined />,
      key: 'twitter',
      link: 'https://twitter.com/ArifulhaqA',
    }
  ]

  return (
    <div className={style.footer}>
      <div className={style.footerMenuContainer}>
      {
        items.map((menu) => {
          return (
            <div key={menu.key} className={style.footerMenu}>
              {menu.label}
              </div>
        )})
      }
      </div>
      <div className={style.footerDivider}></div>
      <div className={style.socialIconsContainer}>
        {
          socialIcons.map((icon) => {
           return <div key={icon.key} className={style.socialIcon}>
             <a href={icon.link} target='_blank' rel='noopener noreferrer' className={style.iconLinkTag}>
             {icon.platformIcon}
             </a>
              </div>
          })
        }
      </div>
      <div className={style.acknowledgmentContainer}>
        <SimpleParagraph
         paragraph={t('copyRight')}
         size='14'
         bold
         css={`${style.letterSpacing} ${urduStyles}`}
        />
      </div>
      <div className={style.brandingContainer}>
      <SimpleParagraph
         paragraph={t('credits')}
         size='14'
         bold
         css={`${style.brandText} ${urduStyles}`}
        />
            <a className={style.brandLink} href='https://pro-file.tech/' target='_blank' rel='noreferrer'>
            <SimpleParagraph
         paragraph={t('teamName')}
         size='14'
         bold
         css={`${style.brandText} ${urduStyles}`}
        />
            </a>
      </div>
    </div>
  )
}

export default Footer