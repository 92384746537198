import React, { lazy }  from "react";
import { UNAUTHENTICATED_ROUTES } from "./Constants";
import CustomRoute from "./CustomRoutes";
// import { Route, Redirect } from "react-router-dom";

const LandingPage = lazy(() => import("../Containers/LandingPage"));
const ParticularBlog = lazy(() => import("../Containers/ParticularBlog"));
const Blogs = lazy(() => import("../Containers/Blogs"));
const Articles = lazy(() => import("../Containers/Articles"));
const About = lazy(() => import("../Containers/About"));
const Videos = lazy(() => import("../Containers/Videos"))
const Login = lazy(()=> import("../Containers/Authentication/Login"))
const MyLifeStories = lazy(() => import("../Containers/MyLifeStories"));
const ParticularStory = lazy(() => import("../Containers/ParticularStory"));




export default function UnAuthenticatedApp() {
  return (
    <>
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.LANDING_PAGE}
        component={LandingPage}
        title="Main Page"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.BLOGS}
        component={Blogs}
        title="Blogs"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.PARTICULAR_BLOG}
        component={ParticularBlog}
        title="Particular Blog"
      />
       <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.VIDEOS}
        component={Videos}
        title="Videos"
      />
       <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.ARTICLES}
        component={Articles}
        title="Articles"
      />
        <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.ABOUT}
        component={About}
        title="About"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.LOGIN}
        component={Login}
        title="Login"
        />
        
       <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.MY_LIFE_STORIES}
        component={MyLifeStories}
        title="My Life Stories"
      />
       <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.PARTICUALT_LIFE_STORY}
        component={ParticularStory}
        title="Specific Story"
      />
      

      {/* <Route exact path="*" component={LandingPage}>
          <Redirect to={UNAUTHENTICATED_ROUTES.LANDING_PAGE} />
      </Route> */}
      </>
  );
}
