import { createSlice } from "@reduxjs/toolkit";
const INITIAL_STATE =  {
    locale: 'ur'
};

const LanguageSlice = createSlice({
  name: "language",
  initialState: INITIAL_STATE,
  reducers: {
    setLinguistics: (state, action) => {
        return {
            locale: action.payload
        }
    },
    clearLinguistics: (state, action) => {
      return INITIAL_STATE;
    }
  },
});

const { reducer } = LanguageSlice;
export default reducer;
export const { setLinguistics, clearLinguistics } = LanguageSlice.actions;
