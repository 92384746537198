import { Menu } from 'antd';
import React from 'react'
import { logout } from '../../AppRedux/Slices/AuthSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    LockOutlined, 
    VideoCameraOutlined,
    FileTextOutlined,
    FileOutlined
  } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import LogoutModal from '../Authentication/LogoutModal';
import { useTranslate } from 'react-redux-multilingual';
import useUrduStyles from '../../Hooks/useUrduStyles';
import blogsImage from "../../Assets/blogs.png"


function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  

const AdminSideMenu = () => {

    
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedKey, setSelectedKey] = useState(
      location.pathname.split("-")[1]
    );
    const t = useTranslate();
    const urduStyles = useUrduStyles();
      
    const items = [
      getItem(t("blogs"), "add-blog", <FileTextOutlined />),
      getItem(t("articles"), "add-article", <FileOutlined />),
      getItem(t("myLifeStories"), "add-story", <FileTextOutlined />),
      getItem(t('videos'), "add-videos", <VideoCameraOutlined />),
      getItem(t('logout'), "logout", <LockOutlined />),
    ];
  
    useEffect(() => {
      setSelectedKey(location.pathname.split("-")[1]);
    });
  
    const onClick = (e) => {
      if (e.key === "logout") {
        showModal();
      } else {
        history.push(`/${e.key}`);
      }
    };
  
    const closeModal = () => {
      setIsModalVisible(false);
    };
    const showModal = () => {
      setIsModalVisible(true);
    };
    const handleLogout = () => {
        console.log("In Logout")
      dispatch(logout());
      history.push("/");
    };
  
  return (
    <div>

         <LogoutModal
    closeModal={closeModal}
    showModal={showModal}
    handleLogout={handleLogout}
    isModalVisible={isModalVisible}
  />

        <Menu
    onClick={onClick}
    style={{
      width: "100%",
      direction:"rtl"
     
    
    }}
    defaultSelectedKeys={[selectedKey]}
    mode='inline'
    items={items}
  />
    </div>
  )
}

export default AdminSideMenu