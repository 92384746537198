export const PAGINATION_CONSTANT = {
  PAGE_SIZE: 10,
  PAGE_NUMBER: 1,
};

export const APP_NAME_MINI = "Arif Portfolio";

export const LOCAL_STORAGE_KEYS = {
  TOKEN: "TOKEN",
  EXTERNAL_TOKEN: "EXTERNAL_TOKEN",
  THEME_TYPE: "theme_type",
  ADMIN_INFO: "ADMIN_INFO",
  NEIGHBORHOOD_LOOKUP: "NEIGHBORHOOD_LOOKUP",
};

export const getYouTubeVideoId = (url) => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([\w]+)(?:\S+)?$/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export const strLimiter = (str, maxLength = 200) => {
  if (!str) {
    return "";
  }
  if (str.length <= maxLength) {
    return str.trimEnd();
  }
  const sliceString = str.slice(0, maxLength - 1);
  const indexToTrimTheWhiteSpace = sliceString.lastIndexOf(" ");
  if (indexToTrimTheWhiteSpace < 0) {
    return `${sliceString.trimEnd()}...`;
  }
  const trimmedStr = sliceString.slice(0, indexToTrimTheWhiteSpace);
  return `${trimmedStr}...`;
}