import React from 'react'
import Modal from "../../../Components/common/Modal"
import styles from "./index.module.less";
import { SimpleHeading } from "../../../Components/common/Heading";
import {SimpleParagraph} from "../../../Components/common/Paragraph/index"
import { Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslate } from 'react-redux-multilingual';
import useUrduStyles from '../../../Hooks/useUrduStyles';


const LogoutModal = ({handleLogout, closeModal, showModal, isModalVisible}) => {

  const t = useTranslate();
  const urduStyles = useUrduStyles();
  return (
    <div className={styles.modalWrapper}>
             <Modal
        cssClass={styles.modal}
        closable="false"
        body={
          <div>
            <Row className={styles.logoutModalRow}>
            <SimpleParagraph paragraph={t("logoutConfirmation")} size="16" margin="0px 0px" 
            
            
            cssClass={urduStyles}
            
            />
            </Row>
        
          </div>
        }
        width={600}
        visible={isModalVisible}
        okText={t('logout')}
        handleOkSubmit={handleLogout}
        handleCancel={closeModal}
        cancelText={t('cancel')}
        title={
          <div className={styles.modalTitle}>
            <SimpleHeading heading={t('logout')} size="18" margin="0px 0px" />
            <WarningOutlined className={styles.warningIcon}/>
          </div>
        }
      />
    </div>
  )
}

export default LogoutModal