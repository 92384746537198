import { configureStore } from "@reduxjs/toolkit";
import {IntlReducer as Intl} from 'react-redux-multilingual';
import languageReducer from "../Slices/LanguageSlice";
import authReducer from "../Slices/AuthSlice";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory()

const reducers =  {
  Intl,
  language: languageReducer,
  auth: authReducer,
};

const store = configureStore({
  reducer: reducers,
  preloadedState: {
     Intl: { locale: 'ur' } 
  },
  devTools: true,
});

export default store;
