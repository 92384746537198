export const translations = {
    // en is the locale prop for IntlProvider
    en: {
      locale: 'en-US',
      // Your language specific messages goes here. Make sure to keep the keys same for all the locale
      messages: {
        
        home: 'Home',
        blogs: 'Blogs',
        videos: 'Videos',
        about: 'About',
        myLifeStories: 'My Life Stories',
        articles: 'Articles',
        articleHead:"Add article title",
        articleDescription:"Add article details",
        coverImageHeader:
          'The Senior journalist and columnist.',
        coverImageParagraph: 'I still believe that if your aim is to change the world, journalism is a more immediate short-term weapon.',
        photoGallery:"Photo Gallery",
        viewMyBooks: 'View all Books',
        myBooks: 'My Books',
        myVideos: 'My Videos',
        logout:"Logout",
        viewMyVideos: 'View all Videos',
        copyRight: '© 2023 Arif-ul-Haq, All Rights Reserved',
        credits: 'Developed by ',
        headline:"Headline",
        teamName: 'Pro-File',
        letsConnect: "Let's Connect",
        contactUsDescription: "For my latest content update or details just drop an email, I will respond you as soon as possible.",
        senderName: 'Enter Your Name',
        emailSubject: 'Enter Subject',
        emailAddress: 'Enter Your Email Address',
        emailMessage: 'Message',
        contactUsSubmitButton: 'Submit',
        blogsHeading: 'My Blogs',
        viewMyBlogs: 'View all Blogs',
        search: 'Search',
        searchPlaceholder: 'Enter your keywords here',
        readMore: 'Read More',
        mangoShake: 'Mango Shake',
        mobileStory: 'Mobile Story',
        eidUlAdha: 'Eid-ul-Adha',
        jumoKashmir: "Jummo Kashmir's resistance literature",
        articleDummyDetailContent: "There are many hiking spots along the Pacific coast in the redwood forests south and north of the city of Eureka. Visiting and hiking each of these points is not a day's work. Saw the huge diameters of three thousand two hundred and three thousand year old trees of Wood and hiked on one of the trails there and the second day was very busy. We hiked north on a beautiful trail near the beach town of Trinidad and managed to reach the highest peak. The trail was half a mile long. We were accompanied by our son-in-law Dr. Arshad Ali, daughter Hina Arif, And two grandsons Ahmed Ali and Asad Ali were there. The hiking took one and a half to two hours and we had lunch at a famous seafood restaurant in the beach market there and then started our return journey. Ended up at 9:30 p.m. at an Iranian restaurant in downtown Sacramento. Jahan Hina and grandsons Ahmed Ali and Asad Ali celebrated Father's Day. Surprised us.",
        aboutMe: "About Me",
        aboutMeSubHeading:"Ariful Haque Arif needs no introduction in Pakistan journalism. He joined the premier newspaper organization Daily Jang, Ariful Haque Arif is a very active and dynamic person.",
        aboutMeContent:'Ariful Haque Arif needs no introduction in Pakistan journalism. He joined the premier newspaper organization Daily Jang in the late 60s and worked there for almost 55 years. Ariful Haque Arif is a very active and dynamic person and is residing in California, USA with his family. Until his retirement from the electronic media arm of Daily Jang “Geo News Channel” which is considered the most-watched news channel in Pakistan, he was heading its censor department as Director. Before joining Geo Channel in 2002, he was with the largest Pakistani newspaper Daily Jang where he served in different positions as Sub-Editor, District Editor, then moved to reporting section as a reporter and then the Chief reporter and Editor special assignments. Recently Mir Shakil Ur Rehman, Group chairman and Editor- in -Chief JangGroup of Newspapers assigned him the responsibility as the Special Correspondent for Daily Jang in California. He also writes columns and articles in various Pakistan and US based Urdu newspapers.',
        journey:'Journey',
        journeyContent:'Ariful Haque Arif is originally from “Khui Ratta” a village in Azad Kashmir where he lived and got his early education until 1960. He then moved to Karachi for a better future and higher education where he completed his Masters in Journalism in 1969. Young Ariful Haque worked very hard in Karachi to earn his livelihood where he worked even as a daily wage worker at Karachi Port trust. Right after his graduation from Karachi university, he joined Daily Jang as a Sub Editor. In April,1967. Then he got married in 1970 to Jamila Begum from Karachi and decided to raise his family in Karachi where he lived until 2012 when he moved to the US for good.',
        city:'Khui Ratta, Azad Kashmir',
        homeland:'His Homeland',
        personality:'Personality',
        personalityContent:"Ariful Haque Arif is an extremely friendly and social person. He loves to travel and has visited various countries and cities around the world in order to know the people, their culture, and history. He likes to be connected to his friends on a regular basis, and his magnetic personality helps him make new friends wherever he goes. To serve this purpose and fondness better, he became active member of a group of senior citizens “MCF Senior Forum in California. He helps manage this group and holds two Zoom meetings a week in which people from around the world take part. ‏ This group ensures that senior people have an outlet to socialize with like-minded people and at the same time discuss religious and social topics of interest. This has now become a global group because its meeting is held online. This group’s success goes to show that even in his 83 plus, Ariful Haque Arif loves to lead and unite people.",
        videoOneTitle: 'IPS TV - Evening with Veteran Journalist Ariful Haque Arif',
        videoTwoTitle: 'BMC Live with Badar Munir Chaudhary, Guest Senior Journalist Ariful Haque Arif',
        videoThreeTitle: 'Meet Ariful Haque Arif - 50+ years Veteran Journalist of Daily Jang and Geo News.',
        videoFourTitle: 'An interview with veteran journalist Ariful Haque Arif',
        videoFiveTitle: 'Toronto 360 TV Interview with Ariful Haque Arif',
        videoBannerHeading: 'Latest Video Title',
        videoBannerDescription: 'An interview with Senior journalist Ariful Haque Arif',
        playTitle: 'Play Video',
        latestArticleHeading: 'My Latest Articles',
        lifeStoryLandingPageHeading: '20 years in journalist society',
        lifeStoryLandingPageParagraph: "There are many hiking spots along the Pacific coast in the redwood forests south and north of the city of Eureka. Visiting and hiking each of these points is not a day's work. Three thousand two hundred and three thousand years old trees saw the diameter of the trees",
        dummyBlogsTitle: 'Great hiking in the redwoods',
        dummyBlogsDescription: 'There are many hiking spots along the Pacific coast in the redwood forests south and north of the city of Eureka.',
        dashboardHead:"Welcome to Dashboard",
        addBlogs:"Add Blog Details",
        blogName:"Enter Blog Name",
        blogDescription:"Enter Blog Description",
        submit:"Submit",
        blogImage:"Upload blog Image",
        storyTitle:"Add story Title",
        chapterTitle:"Chapter Title",
        storyHead:"Add stories of life",
        storyDescription:"Add story details",
        logoutConfirmation:"Do you really want to Log Out?",
        cancel:"Cancel",
        addVideo:"Add Video",
        addVideoTitle:"Add Video Title",
        addVideoUrl:"Add Video Url",
        login:"Login",
        email:"Email address",
        password:"Password",
        adminLogin:"Admin Login"
        
      },
    },
    ur: {
      locale: 'ur',
      messages: {
        home: 'ہوم',
        blogs: 'بلاگز',
        videos: 'ویڈیوز',
        about: 'متعلق',
        myLifeStories: 'میری زندگی کی کہانیاں',
        articles: 'متفرقات',
        articleHead:"متفرق عنوان شامل کریں۔",
        articleDescription:"متفرق تفصیلات شامل کریں۔",
        coverImageHeader: 'سینئر صحافی اور کالم نگار۔',
        coverImageParagraph: 'مجھے اب بھی یقین ہے کہ اگر آپ کا مقصد دنیا کو بدلنا ہے تو صحافت ایک فوری مختصر مدت کا ہتھیار ہے۔',
        photoGallery:"فوٹو گیلری",
        viewMyBooks: 'میری تمام کتابیں دیکھیں',
        myBooks: 'میری کتابیں',
        logout: 'لاگ آوٹ',
        headline:'سرخی',
        myVideos: 'میری ویڈیوز',
        viewMyVideos: 'میری تمام ویڈیوز دیکھیں',
        copyRight: '© 2023 عارف الحق، جملہ حقوق محفوظ ہیں۔',
        credits: 'کے ذریعہ تیار کردہ',
        teamName: 'ٹیم پرو - فائل ',
        letsConnect: 'آئیے رابطہ کریں۔',
        contactUsDescription: 'میرے تازہ ترین مواد کی تازہ کاری یا تفصیلات کے لیے صرف ایک ای میل چھوڑیں، میں آپ کو جلد از جلد جواب دوں گا۔',
        senderName: 'آپ کا نام',
        emailSubject: 'موضوع کا عنوان',
        emailAddress: 'آپ کا ای-میل',
        emailMessage: 'پیغام',
        contactUsSubmitButton: 'جمع کرائیں',
        blogsHeading: 'میرے بلاگز',
        viewMyBlogs: 'تمام بلاگز دیکھیں',
        search: 'تلاش کریں',
        searchPlaceholder: 'اپنے کلیدی الفاظ یہاں درج کریں',
        readMore: 'مزید پڑھیں',
        mangoShake: 'آم کا شیک',
        mobileStory: 'موبائل کی کہانی',
        eidUlAdha: 'بڑی عید ',
        jumoKashmir: 'جموں کشمیر کا مزاحمتی ادب',
        articleDummyDetailContent: 'یوریکا شہر کے جنوب اور شمال میں ریڈ ووڈ کے جنگلات میں بحر الکاہل کے ساحل کے ساتھ پیدل سفر کے بہت سے مقامات ہیں۔ ان پوائنٹس میں سے ہر ایک کا دورہ کرنا اور پیدل سفر کرنا ایک دن کا کام نہیں ہے۔ لکڑی کے تین ہزار دو سو تین ہزار سال پرانے بڑے بڑے درختوں کو دیکھا اور وہاں کے ایک پگڈنڈی پر پیدل سفر کیا اور دوسرا دن بہت مصروف رہا۔ ہم نے ٹرینیڈاڈ کے ساحلی شہر کے قریب ایک خوبصورت پگڈنڈی پر شمال کی طرف پیدل سفر کیا اور بلند ترین چوٹی تک پہنچنے میں کامیاب ہو گئے۔ پگڈنڈی آدھا میل لمبی تھی۔ ہمارے ساتھ ہمارے داماد ڈاکٹر ارشد علی، بیٹی حنا عارف اور دو پوتے احمد علی اور اسد علی بھی تھے۔ ہائیکنگ میں ڈیڑھ سے دو گھنٹے لگے اور ہم نے وہاں بیچ مارکیٹ کے ایک مشہور سی فوڈ ریسٹورنٹ میں لنچ کیا اور پھر واپسی کا سفر شروع کیا۔ رات 9:30 پر ختم ہوا۔ شہر سیکرامنٹو کے ایک ایرانی ریستوران میں۔ جہاں حنا اور پوتے احمد علی اور اسد علی نے فادرز ڈے منایا۔ ہمیں حیران کر دیا۔',
        aboutMe:'میرے بارے میں',
        aboutMeSubHeading:'پاکستانی صحافت میں عارف الحق کسی تعارف کے محتاج نہیں۔ انہوں نے اخبار کی اہم تنظیم روزنامہ جنگ میں شمولیت اختیار کی، عارف الحق بہت متحرک اور متحرک انسان ہیں۔',
        aboutMeContent:'پاکستانی صحافت میں عارف الحق کسی تعارف کے محتاج نہیں۔ انہوں نے 60 کی دہائی کے اواخر میں اخباری تنظیم ڈیلی جنگ میں شمولیت اختیار کی اور تقریباً 55 سال تک وہاں کام کیا۔ عارف الحق ایک بہت متحرک اور متحرک انسان ہیں اور اپنے خاندان کے ساتھ کیلیفورنیا، امریکہ میں مقیم ہیں۔ روزنامہ جنگ "جیو نیوز چینل" کے الیکٹرانک میڈیا بازو سے ریٹائر ہونے تک جو پاکستان میں سب سے زیادہ دیکھا جانے والا نیوز چینل سمجھا جاتا ہے، وہ ڈائریکٹر کے طور پر اس کے سنسر ڈیپارٹمنٹ کی سربراہی کر رہے تھے۔ 2002 میں جیو چینل جوائن کرنے سے پہلے، وہ پاکستان کے سب سے بڑے اخبار روزنامہ جنگ کے ساتھ تھے جہاں انہوں نے سب ایڈیٹر، ڈسٹرکٹ ایڈیٹر کے طور پر مختلف عہدوں پر کام کیا، پھر بطور رپورٹر رپورٹنگ سیکشن میں چلے گئے اور پھر چیف رپورٹر اور ایڈیٹر کی خصوصی اسائنمنٹس۔ حال ہی میں میر شکیل الرحمٰن، گروپ کے چیئرمین اور ایڈیٹر انچیف جنگ گروپ آف نیوز پیپرز نے انہیں کیلیفورنیا میں روزنامہ جنگ کے خصوصی نامہ نگار کی ذمہ داری سونپی۔ وہ پاکستان اور امریکہ کے مختلف اردو اخبارات میں کالم اور مضامین بھی لکھتے ہیں۔',
        journey:'سفر',
        journeyContent:'عارف الحق کا اصل تعلق آزاد کشمیر کے ایک گاؤں "کھوئی رٹہ" سے ہے جہاں وہ رہا اور ابتدائی تعلیم 1960 تک حاصل کی۔ پھر وہ بہتر مستقبل اور اعلیٰ تعلیم کے لیے کراچی چلے گئے جہاں انھوں نے 1969 میں صحافت میں ماسٹرز کیا۔ نوجوان عارف الحق نے کراچی میں بہت محنت کی تاکہ روزی روٹی کمانے کے لیے کراچی پورٹ ٹرسٹ کے طور پر روزانہ کام کیا۔ کراچی یونیورسٹی سے گریجویشن کے فوراً بعد وہ روزنامہ جنگ میں بطور سب ایڈیٹر شامل ہو گئے۔ اپریل، 1967 میں۔ پھر ان کی شادی 1970 میں کراچی کی جمیلہ بیگم سے ہوئی اور کراچی میں اپنے خاندان کی پرورش کرنے کا فیصلہ کیا جہاں وہ 2012 تک رہے جب وہ اچھے کام کے لیے امریکا چلے گئے۔',
        city:'کھوئی رٹہ، آزاد کشمیر',
        homeland:'ان کا وطن',
        personality:'شخصیت',
        personalityContent:'عارف الحق انتہائی ملنسار اور ملنسار انسان ہیں۔ اسے سفر کرنا پسند ہے اور اس نے لوگوں، ان کی ثقافت اور تاریخ کو جاننے کے لیے دنیا کے مختلف ممالک اور شہروں کا دورہ کیا ہے۔ وہ اپنے دوستوں سے مستقل طور پر جڑا رہنا پسند کرتا ہے، اور اس کی مقناطیسی شخصیت جہاں بھی جاتا ہے اسے نئے دوست بنانے میں مدد کرتا ہے۔ اس مقصد اور شوق کو بہتر طریقے سے انجام دینے کے لیے، وہ کیلیفورنیا میں بزرگ شہریوں کے ایک گروپ "MCF سینئر فورم" کا فعال رکن بن گیا۔ وہ اس گروپ کو منظم کرنے میں مدد کرتا ہے اور ہفتے میں دو زوم میٹنگز کرتا ہے جس میں دنیا بھر سے لوگ حصہ لیتے ہیں۔ یہ گروپ اس بات کو یقینی بناتا ہے کہ بزرگ لوگوں کے پاس ہم خیال لوگوں کے ساتھ میل جول رکھنے اور ساتھ ہی دلچسپی کے مذہبی اور سماجی موضوعات پر بات چیت کرنے کا ایک ذریعہ ہو۔ یہ اب ایک عالمی گروپ بن گیا ہے کیونکہ اس کی میٹنگ آن لائن ہوتی ہے۔ اس گروپ کی کامیابی یہ ظاہر کرتی ہے کہ اپنے 83 پلس میں بھی عارف الحق لوگوں کی رہنمائی اور متحد ہونا پسند کرتے ہیں۔',
        videoOneTitle: 'آئی پی ایس ٹی وی - تجربہ کار صحافی عارف الحق کے ساتھ شام',
        videoTwoTitle: 'بی ایم سی لائیو بدر منیر چودھری کے ساتھ، مہمان سینئر صحافی عارف الحق',
        videoThreeTitle: 'روزنامہ جنگ اور جیو نیوز کے 50+ سال کے تجربہ کار صحافی عارف الحق سے ملیں۔',
        videoFourTitle: 'معروف صحافی عارف الحق کا انٹرویو',
        videoFiveTitle: 'ٹورنٹو 360 ٹی وی پر عارف الحق کا انٹرویو',
        videoBannerHeading: 'تازہ ترین ویڈیوز',
        videoBannerDescription: 'معروف صحافی عارف الحق کا انٹرویو',
        playTitle: 'ویڈیو چلائیں۔',
        latestArticleHeading: 'میرے تازہ ترین متفرقات ',
        lifeStoryLandingPageHeading: 'صحافتی معاشرے میں 20 سال',
        lifeStoryLandingPageParagraph: 'یوریکا شہر کے جنوب اور شمال میں ریڈ ووڈ کے جنگلات میں بحر الکاہل کے ساحل کے ساتھ پیدل سفر کے بہت سے مقامات ہیں۔ ان پوائنٹس میں سے ہر ایک کا دورہ کرنا اور پیدل سفر کرنا ایک دن کا کام نہیں ہے۔ تین ہزار دو سو تین ہزار سال پرانے درختوں کا قطر دیکھا',
        dummyBlogsTitle: 'ریڈ ووڈس میں زبردست پیدل سفر',
        dummyBlogsDescription: 'یوریکا شہر کے جنوب اور شمال میں ریڈ ووڈ کے جنگلات میں بحر الکاہل کے ساحل کے ساتھ پیدل سفر کے بہت سے مقامات ہیں۔',
        dashboardHead:"ڈیش بورڈ میں خوش آمدید",
        addBlogs:"بلاگ کی تفصیلات شامل کریں۔",
        blogName:"بلاگ کا نام درج کریں۔",
        blogDescription:"بلاگ کی تفصیل درج کریں۔",
        submit:"جمع کرائیں ",
        blogImage: "بلاگ کی تصویر اپ لوڈ کریں۔",
        storyTitle:"کہانی کا عنوان شامل کریں۔",
        storyHead:"زندگی کی کہانیاں شامل کریں۔",
        storyDescription:"کہانی کی تفصیلات شامل کریں۔",
        logoutConfirmation:"کیا آپ واقعی لاگ آؤٹ کرنا چاہتے ہیں؟ ",
        cancel:"منسوخ",
        mandatoryField:"",
        addVideo:"ویڈیو شامل کریں۔",
        addVideoTitle:"ویڈیو کا عنوان شامل کریں۔",
        addVideoUrl:"ویڈیو لنک شامل کریں۔",
        chapterTitle:"باب کا عنوان",
        adminLogin:"ایڈمن لاگ ان",
        email:"ای میل اڈریس",
        password:"پاس ورڈ",
        login:"لاگ ان کریں",

        
      },
    },
  };
  