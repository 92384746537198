import classNames from 'classnames';
import { useSelector } from 'react-redux';

const useUrduStyles = () => {
  const locale = useSelector(state => state.language.locale);

  const urduClass = classNames({
    'urduFont': locale === 'en' ? false : true,
  });

  return urduClass;
};

export default useUrduStyles;
