import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { getNextPageParam } from "./Services/react-query.service";
import FullPageLoader from "./Components/FullPageLoader/FullPageLoader";
import store, { history } from "./AppRedux/Store";
import { Switch } from "react-router-dom";
import LocalStorageService from "./Services/local-storage.service";
import CustomRoute from "./Routes/CustomRoutes";
import MainApp from "./Containers/App/MainApp";
import UnAuthenticatedApp from "./Routes/UnauthenticatedApp";
import { UNAUTHENTICATED_ROUTES } from "./Routes/Constants";
import AuthService from "./Utils/Auth.utils";
import ResetPassword from "./Containers/Authentication/ResetPassword";
import './App.less';
import LayoutWrapper from "./Layout/LayoutWrapper";
import { IntlProvider } from "react-redux-multilingual";
import { translations } from "./Language";

const isResetUser = history.location.pathname.includes("/reset-password");
const isTokenExist = AuthService.isTokenExist();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
      refetchOnWindowFocus: false,
      retry: false,
      getNextPageParam: (lastPage, allPages) =>
        getNextPageParam(lastPage, allPages),
    },
  },
});


function App() {
  return (
    <Suspense fallback={<FullPageLoader />}>
      <Provider store={store}>
      <IntlProvider translations={translations} locale="en">
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
          <Switch>
          <LayoutWrapper/>
          </Switch>
          </Router>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
        </IntlProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
