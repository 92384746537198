import React from "react";
import CustomRoute from "../../Routes/CustomRoutes";
import { AUTHENTICATED_ROUTES } from "../../Routes/Constants";
import Dashboard from "../Authentication/Dashboard"
import AddBlogs from "../Authentication/AddBlogs"
import AddArticle from "../Authentication/AddArticle"
import AddVideos from "../Authentication/AddVideos"
import AddMyStory from "../Authentication/AddMyStory"

const MainApp = () => {
  return (
    <div>
      <CustomRoute
        exact
        path={AUTHENTICATED_ROUTES.ADD_BLOG}
        component={Dashboard}
        children={<AddBlogs/>}
        title="BLOG"
      />


      
<CustomRoute
        exact
        path={AUTHENTICATED_ROUTES.ADD_ARTICLE}
        children={<AddArticle/>}
        component={Dashboard}
        title="ARTICLE"
      />

<CustomRoute
        exact
        path={AUTHENTICATED_ROUTES.ADD_MY_STORY}
        children={<AddMyStory/>}
        component={Dashboard}
        title="MY STORY"
      />

      
<CustomRoute
        exact
        path={AUTHENTICATED_ROUTES.ADD_VIDEOS}
        children={<AddVideos/>}
        component={Dashboard}
        title="VIDEOS"
      />
      
    </div>
  );
};

export default MainApp;
