import { createSlice } from "@reduxjs/toolkit";
const initialValue = {};

const AuthSlice = createSlice({
  name: "auth",
  initialState: initialValue,
  reducers: {
    logout: (state, action) => {
      localStorage.clear();
      state.auth =  null;
    },

    signin: (state, action) => {
      state.auth = action.payload
     
    },
  },
});

const { reducer } = AuthSlice;
export default reducer;
export const {  signin, logout } = AuthSlice.actions;
