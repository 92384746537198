import { message } from "antd";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {storage} from "../../src/Services/firebaseConfig"


const uploadImage = async ({ id, file }) => {
    
    try {
      const storageRef = ref(storage, `assets/${id}`);
      await uploadBytes(storageRef, file, { contentType: file.type });
  
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      message.error('Something went wrong');
      throw error; // Rethrow the error to handle it at a higher level if needed
    }
  }
export const global = {
    uploadImage,
};
  