import { Col, Form, Image, Row } from "antd";
import React, { useEffect, useState } from "react";
import Logo from "../Assets/logo.svg";
import { useWindowWidth } from "@react-hook/window-size";
import {  LoginOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import style from "./index.module.less";
import MenuItem from "../Components/common/MenuItem";
import Sidebar from "../Components/common/Sidebar";
import { useSelector } from "react-redux";
// import LogoutModal from "../Components/LogoutModal/LogoutModal";
// import { logout } from "../Redux/Slices/AuthSlice";

const Header = () => {
  const isMobile = useWindowWidth() > 900;
  const isSmallScreen = useWindowWidth() < 600;
  const [form] = Form.useForm();
  const isLoggedIn = localStorage.getItem('TOKEN');
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const linguistics = useSelector((state) => state.language.locale);

  useEffect(() => {
    form.setFieldValue('language', linguistics)
  }, [linguistics, form])
  
  
  // const closeModal = () => {
  //   setIsModalVisible(false);
  // };

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleLogout = () => {
  //   closeModal()
  //   dispatch(logout());
  //   history.push("/");
  // }

  // const onLanguageChange = (value) => {
  //   if(value) {
  //   dispatch(IntlActions.setLocale(value))
  //   dispatch(setLinguistics(value))
  // }
  // }

  const showDrawer = () => {
    setVisible(true);
  };
  return (
    <div>
       {/* <LogoutModal closeModal={closeModal} showModal={showModal} handleLogout={handleLogout} isModalVisible={isModalVisible}/> */}
      <Row>
        <Col lg={6} md={6} sm={6} xs={6}>
          <Link to='/'>
            <Image
              src={Logo}
              preview={false}
              width={isSmallScreen ? 150 : 180}
              alt='Arif-ul-Haq'
            />
          </Link>
        </Col>
        <Col xs={18} sm={18} md={18} lg={18} xxl={18} className={style.menuArea}>
        {isMobile ? (
          <>
              <MenuItem />
              {!isLoggedIn && <Link to="/login">
              <LoginOutlined size={"large"} className={style.loginButton} />
              </Link>}
          </>
              ) : (
                <div className={style.menuAreaMobile}>
            <UnorderedListOutlined onClick={showDrawer} size={"large"} className={style.hamBurger} />
            <Link to="/login">
              <LoginOutlined size={"large"} className={style.loginButton} />
              </Link>
            <Sidebar visible={visible} setVisible={setVisible} />
          </div>
        )}
            {/* <Form
              name="languageForm"
              layout="vertical"
              form={form}
              className={style.languageForm}
             >
             <Selector
              label={null}
              options={languageOptions}
              placeholder={'Select Language'}
              validationKey={'notRequired'}
              name={'language'}
              handleChange={onLanguageChange}
              />
             </Form> */}
        </Col>
      </Row>
    </div>
  );
};

export default Header;
