import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCJHcdR-G0GyheqHvHcbOkzp0lW-3p5K58",
  authDomain: "arif-ul-haq-portfolio.firebaseapp.com",
  projectId: "arif-ul-haq-portfolio",
  storageBucket: "arif-ul-haq-portfolio.appspot.com",
  messagingSenderId: "707576558816",
  appId: "1:707576558816:web:5df73db90d4ae2c4d0cc85",
  measurementId: "G-T6X6NXKDLR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app)