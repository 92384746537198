import React from "react";
import { Menu } from "antd";
import {useTranslate} from 'react-redux-multilingual';
import { SimpleParagraph } from "../common/Paragraph";
import useUrduStyles from "../../Hooks/useUrduStyles";
import { Link } from "react-router-dom";
import style from "./index.module.less";

const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

const Inlinenavbar = () => {
  const t = useTranslate();
  const urduStyles = useUrduStyles();
  const [openKeys, setOpenKeys] = React.useState(["home"]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const items = [
    {
      label: (
        <Link to='/'>
          <SimpleParagraph
            paragraph={t('home')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "home",
    },
    {
      label: (
        <Link to='/about'>
          <SimpleParagraph
            paragraph={t('about')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
            />
        </Link>
      ),
      key: "about",
      children: [{
        label: <Link to='/my-life-stories'>{t('myLifeStories')}</Link>,
        key: "myLifeStories",
      }]
    }, 
    {
      label: (
        <Link to='/blogs'>
          <SimpleParagraph
            paragraph={t('blogs')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "blogs",
    },
    {
      label: (
        <Link to='/articles'>
          <SimpleParagraph
            paragraph={t('articles')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "articles",
    },
    {
      label: (
        <Link to='/videos'>
          <SimpleParagraph
            paragraph={t('videos')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "videos",
    }, 
  ];

  return (
    <>
    <Menu
      selectable={false}
      mode='inline'
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        width: "100%",
        padding: "2rem 0rem",
      }}
      items={items}
    />
      </>
  );
};

export default Inlinenavbar;
