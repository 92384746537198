export const UNAUTHENTICATED_ROUTES = {
  LANDING_PAGE: "/",
  BLOGS: '/blogs',
  PARTICULAR_BLOG: '/blogs/:id',
  RESET_APP_PASSWORD: "/reset-password/:resetCode/:Email",
  ARTICLES: '/articles',
  ABOUT: '/about',
  LOGIN:'/login',
  VIDEOS: '/videos',
  MY_LIFE_STORIES: "/my-life-stories",
  PARTICUALT_LIFE_STORY: "/story/:id"

  // EVENT_SUCCESS: "/event-submitted"
  // RESET_PASSWORD: "/reset-password/:resetToken",
  // FORGET_PASSWORD: "/forget-password",
};

export const AUTHENTICATED_ROUTES = {
  DASHBOARD:'/dashboard',
  ADD_BLOG:"/add-blog",
  ADD_ARTICLE:"/add-article",
  ADD_MY_STORY:"/add-story",
  ADD_VIDEOS:"/add-videos"
};
