import React from "react";
import { Menu } from "antd";
import {useTranslate} from 'react-redux-multilingual';
import { SimpleParagraph } from "../Paragraph";
import style from "./index.module.less";
import { Link } from "react-router-dom";
import useUrduStyles from "../../../Hooks/useUrduStyles";

const MenuItem = () => {
  const [current, setCurrent] = React.useState('home');
  const t = useTranslate();
  const urduStyles = useUrduStyles();


  const items = [
    {
      label: (
        <Link to='/videos'>
          <SimpleParagraph
            paragraph={t('videos')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "videos",
    }, 
    {
      label: (
        <Link to='/articles'>
          <SimpleParagraph
            paragraph={t('articles')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "articles",
    },
    {
      label: (
        <Link to='/blogs'>
          <SimpleParagraph
            paragraph={t('blogs')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "blogs",
    },
    {
      label: (
        <Link to='/about'>
          <SimpleParagraph
            paragraph={t('about')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
            />
        </Link>
      ),
      key: "about",
      children: [{
        label: <Link to='/my-life-stories'>{t('myLifeStories')}</Link>,
        key: "myLifeStories",
      }]
    }, 
    {
      label: (
        <Link to='/'>
          <SimpleParagraph
            paragraph={t('home')}
            size='14'
            bold
            css={`${style.letterSpacing} ${urduStyles}`}
          />
        </Link>
      ),
      key: "home",
    },
  ];

  const onClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode='horizontal'
      items={items}
      className={style.MenuItemWrapper}
    />
  );
};

export default MenuItem;
