import React from 'react'
import { useState, useEffect } from 'react';
import { logout } from '../../../AppRedux/Slices/AuthSlice';
import { Col, Menu, Row } from 'antd';
import { SimpleHeading } from '../../../Components/common/Heading';
import AdminSideMenu from '../../AdminSideMenu';
import styles from "./index.module.less"
import { useTranslate } from 'react-redux-multilingual';
import useUrduStyles from '../../../Hooks/useUrduStyles';
import style from "./index.module.less"


  
const Dashboard = ({children}) => {

  const t = useTranslate();
  const urduStyles = useUrduStyles();


  return (
    <>
    <div className={styles.adminDashboard}>
     <div className={styles.adminMainDashboard}>
       <Row gutter={[24,24]}  className={style.aboutMeParagraph}>
       <SimpleHeading heading={t('dashboardHead')} size={24} weight={"bold"} margin={"20px 10px"}
        css={` ${urduStyles}`}
       />
       </Row>
       <Row gutter={[24,24]} className={styles.mainSectionRow}>
       <Col xs={24} sm={24} md={20} lg={20} xl={20} className={styles.adminMainSection}>
         {children}
         </Col>
         <Col xs={0} sm={0} md={4} lg={4} xl={4} className={styles.adminSideSection}>
           <AdminSideMenu/>
         </Col>
       
       </Row>

     </div>
     
    </div>
  
  </>
  )
}

export default Dashboard